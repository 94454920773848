import React from "react";
import "../Hojas-de-estilo/main.css";
import { FaDatabase, FaDiceD6, FaCogs, FaCoins } from "react-icons/fa";

export function Maintext(props) {
  return (
    <div className="contenedor-main">
      <div className="contenedor-texto-main">
        <p className="tema-contenido-main">
          <strong>{props.tema}</strong>
        </p>
        <p className="subtema-main">
          <strong>{props.subtema}</strong>{" "}
        </p>
        <p className="texto-main">
          {props.testimonio1} <strong>{props.testimonio2}</strong>{" "}
          {props.testimonio3}
        </p>
      </div>
    </div>
  );
}

export function Mainfig() {
  return (
    <div className="contenedor-mainfig">
      <button className="boton-mainfig">
        <h1>
          <FaDatabase />
        </h1>
      </button>
      <button className="boton-mainfig"><a href="#sig">Misión</a></button>
      <button className="boton-mainfig">
        <h1>
          <FaDiceD6 />
        </h1>
      </button>
      <button className="boton-mainfig"><a href="#sig">Visión</a></button>
      <button className="boton-mainfig">
        <a href="#sig">Sistema Integrado de Gestión</a>
      </button>
      <button className="boton-mainfig"><a href="#sig">Política</a></button>
      <button className="boton-mainfig">
        <h1>
          <FaCogs />
        </h1>
      </button>
      <button className="boton-mainfig"><a href="#sig">Objetivos</a></button>
      <button className="boton-mainfig">
        <h1>
          <FaCoins />
        </h1>
      </button>
    </div>
  );
}
